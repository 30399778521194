<template>
  <div id="nav" v-if="$route.meta.showHeader">
    <router-link to="vita">vita</router-link>
    <router-link to="projects">projekte</router-link>
  </div>
  <Toast />
  <ConfirmDialog />
  <router-view />
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "styles/variables.scss";

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");

* {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#app {
  height: 100%;
  margin: 0;
}

#app {
  display: flex;
  flex-direction: column;
}

#nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;

  a {
    color: grey;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 3rem;

    &:hover {
      color: lightgrey;
    }

    &.router-link-exact-active {
      color: black;
    }
  }
}

.p-field {
  > label {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
  }

  * {
    display: block;
  }

  &:not(:first-child) {
    margin-top: 1rem;
  }
}

.p-button {
  background: #c2c2c2 !important;
  border-color: #c2c2c2 !important;
  color: white !important;

  &:hover {
    background: #9b9b9b !important;
    border-color: #9b9b9b !important;
  }
}
</style>
