<template>
  <div id="projects">
    <ProjectGrid />
  </div>
</template>

<script>
import ProjectGrid from "@/components/ProjectGrid";

export default {
  name: "Projects",
  components: { ProjectGrid }
};
</script>

<style lang="scss">
#projects {
  display: flex;
  flex: 1;
}
</style>
