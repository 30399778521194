<template>
  <div id="welcome">
    <router-link to="projects">colin marggraf</router-link>
  </div>
</template>

<script>
export default {
  name: "Welcome"
};
</script>

<style lang="scss">
#welcome {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  > a {
    font-size: 3.5rem;
    text-decoration: none;
    color: grey;

    &:hover {
      color: lightgrey;
    }
  }
}
</style>
