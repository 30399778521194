import { createRouter, createWebHistory } from "vue-router";
import Welcome from "../views/Welcome.vue";
import Vita from "@/views/Vita";
import Projects from "@/views/Projects";

const routes = [
  {
    path: "/",
    name: "Welcome",
    component: Welcome,
    meta: {
      showHeader: false
    }
  },
  {
    path: "/vita",
    name: "Vita",
    component: Vita,
    meta: {
      showHeader: true
    }
  },
  {
    path: "/projects",
    name: "projects",
    component: Projects,
    meta: {
      showHeader: true
    }
  },
  {
    path: "/ar",
    name: "AR",
    component: () => import("../views/AR.vue"),
    meta: {
      showHeader: false
    }
  },
  {
    path: "/admin",
    component: () => import("../views/admin/Admin.vue"),
    redirect: "/admin/login",
    children: [
      {
        path: "login",
        name: "AdminLogin",
        component: () => import("../views/admin/AdminLogin.vue")
      },
      {
        path: "dashboard",
        name: "AdminDashboard",
        component: () => import("../views/admin/AdminDashboard.vue"),
        meta: {
          requiresAuth: true
        }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (localStorage.getItem("token")) {
      next();
    } else {
      from();
    }
  } else {
    next();
  }
});

export default router;
