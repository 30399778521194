<template>
  <div id="vita">
    <table>
      <tr v-for="vita in vitas" :key="vita._id">
        <td class="vita__date">{{ vita.date }}</td>
        <td class="vita__description">{{ vita.description }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "Vita",
  data() {
    return {
      vitas: []
    };
  },
  beforeCreate() {
    this.axios
      .get("/vitas")
      .then(res => {
        this.vitas = res.data.sort((a, b) => {
            return a.index - b.index;
          });
      })
      .catch(console.error);
  }
};
</script>

<style lang="scss">
#vita {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 0.5rem;

  > table {
    font-size: 1.5rem;
    line-height: 2.5rem;
    color: grey;
  }

  .vita__date {
    white-space: nowrap;
    text-align: end;
    padding-right: 0.5rem;
  }

  .vita__description {
    padding-left: 0.5rem;
  }
}
</style>
