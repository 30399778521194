import { createApp } from "vue";
import App from "./App.vue";
import PrimeVue from "primevue/config";
import "primevue/resources/primevue.min.css";
import axios from "axios";
import "primevue/resources/themes/nova/theme.css";
import "primeicons/primeicons.css";
// import "./assets/_overrides.scss";
// import "primeflex/primeflex.css";
// import "primeflex/src/_grid.scss";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";

//components
import FileUpload from "primevue/fileupload";
import Card from "primevue/card";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import OrderList from "primevue/orderlist";
import Dropdown from "primevue/dropdown";
import DataView from "primevue/dataview";
import ConfirmDialog from "primevue/confirmdialog";
import Slider from "primevue/slider";
import Galleria from "primevue/galleria";
import Textarea from "primevue/textarea";
import Toast from "primevue/toast";

import router from "./router";

const app = createApp(App);

const axios_instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL + "/api"
});
app.config.globalProperties.axios = axios_instance;

const token = localStorage.getItem("token");
if (token) {
    axios_instance.defaults.headers.common["authorization"] = token;
}

app.use(PrimeVue);
app.use(router);
app.use(ConfirmationService);
app.use(ToastService);

app.component("OrderList", OrderList);
app.component("Dropdown", Dropdown);
app.component("DataView", DataView);
app.component("FileUpload", FileUpload);
app.component("Card", Card);
app.component("InputText", InputText);
app.component("PButton", Button);
app.component("PDialog", Dialog);
app.component("ConfirmDialog", ConfirmDialog);
app.component("Slider", Slider);
app.component("Galleria", Galleria);
app.component("PTextarea", Textarea);
app.component("Toast", Toast);

app.mount("#app");
