<template>
  <Galleria
    :value="currentProject.images"
    v-model:activeIndex="activeIndex"
    :responsiveOptions="responsiveOptions"
    :numVisible="7"
    containerStyle="max-width: 850px"
    :circular="true"
    :fullScreen="true"
    :showItemNavigators="currentProject.images.length > 1"
    :showThumbnails="false"
    v-model:visible="displayGallery"
  >
    <template #item="slotProps">
      <img
        :src="`${baseURL}/api/upload/source/${slotProps.item}`"
        alt=""
        style="width: 100%; display: block;"
      />
    </template>
    <template #thumbnail="slotProps">
      <img
        :src="`${baseURL}/api/upload/source/${slotProps.item}`"
        alt=""
        style="display: block;"
      />
    </template>
  </Galleria>

  <div v-if="projects" class="project__list">
    <div
      v-for="(project, index) of projects"
      class="project__list-item"
      :key="index"
      @click="imageClick(project)"
      @mouseover="showGif = project._id"
      @mouseleave="showGif = ''"
    >
      <img
        v-if="project._id === showGif && project.gif"
        :src="`${baseURL}/api/upload/source/${project.gif}`"
        alt=""
      />
      <img
        v-else-if="project.thumbnail"
        :src="`${baseURL}/api/upload/source/${project.thumbnail}`"
        alt=""
      />
      <span v-else>{{ project.title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectGrid",
  data: () => ({
    projects: [],
    currentProject: {
      images: []
    },
    activeIndex: 0,
    displayGallery: false,
    showGif: "",
    responsiveOptions: [
      {
        breakpoint: "1024px",
        numVisible: 5
      },
      {
        breakpoint: "768px",
        numVisible: 3
      },
      {
        breakpoint: "560px",
        numVisible: 1
      }
    ]
  }),
  computed: {
    baseURL() {
      return process.env.VUE_APP_API_URL;
    }
  },
  mounted() {
    if (this.$route.query && this.$route.query['id']) {
      this.currentProject.images = [this.$route.query['id']];
      this.displayGallery = true;
    }

    const vm = this;
    this.axios
      .get("/projects")
      .then(function(response) {
        vm.projects = response.data;
      })
      .catch(function(error) {
        console.log(error);
      });
  },
  methods: {
    imageClick(p) {
      this.currentProject = p;
      this.activeIndex = 0;

      if (this.currentProject.images.length > 0) {
        this.displayGallery = true;
      } else {
        this.currentProject = {};
      }
    }
  }
};
</script>

<style lang="scss">
.project {
  display: flex;
  flex: 0 0 calc(100% - 1rem);
  position: relative;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

@media screen and (min-width: 1000px) {
  .projects .project {
    display: flex;
    flex: 0 0 calc(50% - 1rem);
  }
}

@media screen and (min-width: 1400px) {
  .projects .project {
    display: flex;
    flex: 0 0 calc(33% - 1rem);
  }
}

.project__list {
  display: flex;
  padding: 1rem;
  flex-wrap: wrap;
  gap: 1rem;
  align-content: flex-start;
  justify-content: center;
  width: 100%;

  .project__list-item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      max-height: 20rem;
      max-width: 100%;
    }
  }
}

.gif {
  position: absolute;
  display: none;
}

.project:hover .gif {
  display: block;
}

div.p-galleria-mask.p-component-overlay {
  --maskbg: rgba(124, 124, 124, 0.9);
}
</style>
